/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/high-frequency/'], ['en', 'https://www.hear.com/hearing-loss/high-frequency/'], ['en-US', 'https://www.hear.com/hearing-loss/high-frequency/'], ['en-CA', 'https://ca.hear.com/hearing-loss/high-frequency/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-symptoms--treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-symptoms--treatment",
    "aria-label": "causes symptoms  treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes, symptoms & treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our ears perceive many sounds – especially consonants or hissing sounds such as “s”, “f” and “sh” – in the high-frequency range. In contrast, the vowels a, i, e, u and o are within the low frequency range and are less important for speech intelligibility; they sound muffled and convey the volume of speech instead."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with normal low-frequency perception – who are experiencing high-frequency hearing loss – are unable to clearly hear the person with whom they are speaking, even at a normal volume. High-frequency hearing loss limits speech intelligibility even though perception of volume is normal (isolated high-frequency hearing loss). This kind of hearing loss can be treated with the use of hearing aids, which is why extensive consultation and fittings are very important."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "development-and-causes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#development-and-causes",
    "aria-label": "development and causes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Development and causes"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The causes of high-frequency hearing loss are numerous. However, progressive age and/or sensory hair cells in the inner ear, damaged by noise, are mostly responsible for the loss in high-frequency sounds."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-of-high-frequency-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-of-high-frequency-hearing-loss",
    "aria-label": "symptoms of high frequency hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms of high-frequency hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As mentioned before, high-frequency hearing loss means that speech intelligibility is limited. The perception of volume may be normal, but the person experiencing high-frequency hearing loss often feels that people in their environment “mumble.” Since they usually perceive the volume as normal, the affected persons tend to not take hearing impairment into consideration. However, these symptoms should not be ignored – it is important to treat hearing loss as soon as possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-various-degrees-of-high-frequency-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-various-degrees-of-high-frequency-hearing-loss",
    "aria-label": "the various degrees of high frequency hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The various degrees of high-frequency hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "High-frequency hearing loss can be classified in levels of impairment that vary from person to person in everyday life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Mild high-frequency hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Initially, high-frequency hearing loss becomes noticeable when you experience difficulty hearing in the presence of several people speaking simultaneously or while watching TV. This slight hearing loss can be alleviated with hearing aids – the earlier, the better. An open fitting with a behind-the-ear hearing aid provides the best results."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Moderate high-frequency hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with moderate high-frequency hearing loss have difficulty hearing in group conversations. During one-on-one discussions, the affected person relies heavily on the other person’s viseme in order to lip read what they are saying. Additionally, deep male voices are understood better than high-pitched female or children’s voices. This type of hearing loss can cause family members or friends to frequently suggest that the affected person be provided with a hearing aid. Consequently, the best option for this condition is behind-the-ear devices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Severe high-frequency hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These are people with extensive hearing loss and damage to the inner ear. Speech intelligibility is severely limited, which makes the use of hearing aids unavoidable. Behind-the-ear devices are recommended when high-frequency hearing loss is present. When selecting a device, quality should be a decisive criterion. Our team of experts are here to provide you with essential advice on choosing the right hearing aid – we are confident that our services will help reverse the effects of severe high-frequency hearing loss and improve speech intelligibility."), "\n", React.createElement(ButtonCta, {
    copy: "TRY HEARING AIDS NOW",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-options",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-options",
    "aria-label": "treatment options permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment options"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The different degrees of high-frequency hearing loss can be treated with various hearing aids. The more severe the high-frequency hearing loss, the more difficult the fitting of a hearing aid. It is highly recommended that a special hearing test be performed. During this test, single syllable comprehension is measured at low volume, i.e. 65 dB, in a sound-proofed room. Comprehension rates should be at least 80%."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Lower levels suggest the need for a hearing aid. After your test, an audiologist must have the devices personally fitted. These hearing aids will then amplify the reduced sounds, i.e. the high frequencies in the event of high-frequency hearing loss. Ultimately, the new hearing system will improve speech intelligibility without an increase in volume."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
